import React from 'react';
import classNames from 'classnames';

interface InputProps {
    id: string;
    label?: string;
    required?: boolean;
    pattern?: string;
    value?: string;
    placeholder?: string;
    readonly?: boolean;
    disabled?: boolean;
    onChange?: (value: string) => void;
}

const Input = (props: InputProps) => {
    const {
        id,
        value,
        label,
        pattern,
        placeholder,
        required,
        readonly,
        disabled,
        onChange
    } = props;

    return (
        <div className="flex flex-col gap-2">
            {label && (
                <label
                    htmlFor={id}
                    className="text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            )}
            <input
                type="text"
                id={id}
                className={classNames(
                    'rounded-lg border border-gray-200 bg-white shadow-sm p-2.5 focus:ring-fs-primary focus:border-fs-primary',
                    {
                        'opacity-75 pointer-events-none': readonly || disabled
                    }
                )}
                required={required}
                value={value}
                pattern={pattern}
                placeholder={placeholder}
                readOnly={readonly}
                disabled={disabled}
                onChange={e => {
                    onChange?.(e.target.value);
                }}
            />
        </div>
    );
};

export default Input;
