import Layout from '@/widgets/Layout/ui/Layout';
import { AppHead } from '@/shared/ui/AppHead';
import React from 'react';
import { TrustedBy } from '@/widgets/TrustedBy';
import { ContactUsSection } from '@/widgets/ContactUsSection';
import { Button, ButtonSize } from '@/shared/ui/Button';
import { AppLink } from '@/shared/ui/AppLink';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { Pricing } from '@/widgets/PricingTIAPI';
import ThreatIntelligenceAPI from '@/entities/ThreatIntelligenceAPI';
import { setup } from '@/shared/lib/csrf';
import OurDataEnrichments from '@/entities/Blocks/OurDataEnrichments';
import UseCases from '@/entities/Blocks/UseCases';
import WeCoverThreatTypes from '@/entities/Blocks/WeCoverThreatTypes';
import OurTIDataSources from '@/entities/Blocks/OurTIDataSources';
import TryItNow from '@/entities/Blocks/TryItNow';

export const DESCRIPTION =
    "Offers a detailed security evaluation, highlighting potential risks and delivering threat intelligence. It's an essential tool for augmenting your current security setup with our expert insights. With functionality mirroring our Lookup service, it seamlessly integrates into your security platform.";

export default function ThreatIntelligenceAPIPage() {
    return (
        <Layout>
            <AppHead
                title="Threat Intelligence API | FalconSentinel"
                description={DESCRIPTION}
            />
            <div className="w-full flex flex-col items-center gap-12 sm:gap-16 px-5 xl:px-0">
                <h1 className="max-w-4xl text-gray-800 bg-clip-text text-center text-4xl font-bold tracking-[-0.02em] md:text-6xl">
                    Threat Intelligence API
                </h1>
                <p className="max-w-3xl text-center text-gray-500 md:text-xl">
                    Offers a detailed security evaluation, highlighting
                    potential risks and delivering threat intelligence. It's an
                    essential tool for augmenting your current security setup
                    with our expert insights. With functionality mirroring our{' '}
                    <AppLink
                        href={composeLink({
                            subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                            path: PATHS.THREAT_LOOKUP
                        })}
                        size="2xl"
                        className="inline underline"
                    >
                        Lookup service
                    </AppLink>
                    , it seamlessly integrates into your security platform.
                </p>
                <div className="flex items-center gap-4 sm:gap-12">
                    <a href={'#pricing'}>
                        <Button size={ButtonSize.ExtraExtraLarge}>
                            Pricing
                        </Button>
                    </a>
                    <AppLink
                        href="https://docs.falconsentinel.com/api"
                        size="2xl"
                    >
                        Documentation →
                    </AppLink>
                </div>
                <section className="max-w-3xl w-full">
                    <div className="w-full sm:gap-16 pt-4 pb-8 lg:pb-16">
                        <div className="w-full overflow-hidden rounded-xl border border-fs-primary bg-white shadow-md p-4 sm:p-8">
                            <ThreatIntelligenceAPI />
                        </div>
                    </div>
                </section>
                <UseCases />
                <OurDataEnrichments showMode="API" />
                <WeCoverThreatTypes />
                <OurTIDataSources />
                <TrustedBy />
                <Pricing />
                <TryItNow />
                <ContactUsSection />
            </div>
        </Layout>
    );
}

export const getServerSideProps = setup(async context => {
    return { props: {} };
});
