import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import { faSnowflake } from '@fortawesome/free-regular-svg-icons';
import { Button, ButtonSize } from '@/shared/ui/Button';

const TIDFMarketplaceButtons = () => {
    return (
        <section>
            <div className="flex flex-col items-center gap-12 md:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Database integrations
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Seamlessly access, deploy, and utilize our data feeds
                    through the Snowflake and AWS Marketplaces.
                </p>
                <div className="grid sm:grid-cols-2 gap-8">
                    <a
                        href="https://aws.amazon.com/marketplace/pp/prodview-765xnegicq6kw"
                        target="_blank"
                        rel="noreferrer nofollow"
                        title="Threat Intelligence Data Feeds, Daily Updates | AWS Marketplace"
                    >
                        <Button
                            size={ButtonSize.ExtraExtraLarge}
                            className="flex items-center justify-center gap-4 w-full"
                        >
                            <FontAwesomeIcon className="w-7" icon={faAws} />
                            AWS Marketplace
                        </Button>
                    </a>
                    <a
                        href="https://app.snowflake.com/marketplace/listing/GZTIZ1D7XE/falconsentinel-threat-intelligence-database"
                        target="_blank"
                        rel="noreferrer nofollow"
                        title="Threat Intelligence Data Feeds, Daily Updates | Snowflake Marketplace"
                    >
                        <Button
                            size={ButtonSize.ExtraExtraLarge}
                            className="flex items-center justify-center gap-4 w-full"
                        >
                            <FontAwesomeIcon
                                className="w-5"
                                icon={faSnowflake}
                            />
                            Snowflake Marketplace
                        </Button>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default TIDFMarketplaceButtons;
