import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonTheme } from '@/shared/ui/Button';
import Input from '@/shared/ui/Input';
import HighlightCode from '@/shared/ui/HighlightCode/HighlightCode';
import IocExamples from '@/entities/Lookup/ui/IocExamples/IocExamples';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import Link from 'next/link';
import * as RadioGroup from '@radix-ui/react-radio-group';
import classNames from 'classnames';

interface ThreatIntelligenceApiProps {
    className?: string;
}

const initialIoc = '64.62.197.197';
const initialLookupResults =
    '{\n' +
    '  "firstSeen": 1678320000,\n' +
    '  "lastSeen": 1722394965,\n' +
    '  "verdict": "Threat",\n' +
    '  "score": 1,\n' +
    '  "threatTypes": [\n' +
    '    "Malware",\n' +
    '    "Attack"\n' +
    '  ],\n' +
    '  "location": {\n' +
    '    "country": "United States of America",\n' +
    '    "region": "California",\n' +
    '    "city": "Pleasanton"\n' +
    '  },\n' +
    '  "ptr": {\n' +
    '    "value": "scan-50a.shadowserver.org.",\n' +
    '    "reverseMatch": true\n' +
    '  },\n' +
    '  "netblock": {\n' +
    '    "inetnum": "64.62.128.0 - 64.62.255.255",\n' +
    '    "parent": "NET-64-0-0-0-0",\n' +
    '    "source": "arin",\n' +
    '    "netname": "HURRICANE-4",\n' +
    '    "modified": "2012-02-24T09:44:34-05:00",\n' +
    '    "score": 0.0308,\n' +
    '    "organization": {\n' +
    '      "id": "HURC",\n' +
    '      "name": "Hurricane Electric LLC",\n' +
    '      "email": "",\n' +
    '      "phone": "",\n' +
    '      "address": [\n' +
    '        "760 Mission Court",\n' +
    '        "Fremont",\n' +
    '        "CA",\n' +
    '        "94539",\n' +
    '        "United States"\n' +
    '      ]\n' +
    '    },\n' +
    '    "techContact": {\n' +
    '      "id": "ZH17-ARIN",\n' +
    '      "role": "Hurricane Electric",\n' +
    '      "email": "hostmaster@he.net",\n' +
    '      "phone": "+1-510-580-4100",\n' +
    '      "address": [\n' +
    '        "760 Mission Court",\n' +
    '        "Fremont",\n' +
    '        "CA",\n' +
    '        "94539",\n' +
    '        "United States"\n' +
    '      ]\n' +
    '    },\n' +
    '    "abuseContact": {\n' +
    '      "id": "ABUSE1036-ARIN",\n' +
    '      "role": "Abuse Department",\n' +
    '      "email": "abuse@he.net",\n' +
    '      "phone": "+1-510-580-4100",\n' +
    '      "address": [\n' +
    '        "760 Mission Court",\n' +
    '        "Fremont",\n' +
    '        "CA",\n' +
    '        "94539",\n' +
    '        "United States"\n' +
    '      ]\n' +
    '    }\n' +
    '  },\n' +
    '  "asn": {\n' +
    '    "asn": 6939,\n' +
    '    "name": "Hurricane Electric",\n' +
    '    "domain": "http://he.net"\n' +
    '  },\n' +
    '  "publicVPN": false,\n' +
    '  "torExitNode": false\n' +
    '}';

enum Plan {
    Core = 'core',
    Professional = 'pro',
    Ultimate = 'ultimate'
}

const ThreatIntelligenceApi = (props: ThreatIntelligenceApiProps) => {
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const [ioc, setIoc] = useState(initialIoc);

    const [plan, setPlan] = useState<Plan>(Plan.Ultimate);
    const [lookupIoc, setLookupIoc] = useState(initialIoc);
    const [lookupResults, setLookupResults] = useState(initialLookupResults);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);

    const sendApiForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsLoading(true);
        fetch('/api/threat-intelligence/web', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ioc,
                plan
            })
        })
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(data => {
                if (data) {
                    data = JSON.stringify(data, null, 2);
                    setLookupResults(data);
                    setLookupIoc(ioc);
                }
            })
            .catch(err => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onIocExampleClick = (ioc: string, onSuccess?: () => void) => {
        setIoc(ioc);
        setTimeout(() => {
            submitButtonRef.current?.click();
            onSuccess?.();
        });
    };

    useEffect(() => {
        submitButtonRef.current?.click();
    }, [plan]);

    return (
        <section className="w-full">
            <form className="flex flex-col gap-4" onSubmit={sendApiForm}>
                <div className="gap-4 grid sm:grid-cols-[1fr_auto]">
                    <Input
                        id="threat-intelligence"
                        placeholder="Enter a IPv4 or IPv6 address"
                        required={true}
                        value={ioc}
                        onChange={setIoc}
                    />
                    <div className="flex justify-center">
                        <Button ref={submitButtonRef} isLoading={isLoading}>
                            Lookup
                        </Button>
                    </div>
                </div>
                <div className="text-gray-500 ml-2.5">
                    <IocExamples
                        callback={onIocExampleClick}
                        callbackResult={lookupResults}
                    />
                </div>
                <div className="text-gray-500 ml-2.5">
                    <div className="flex flex-wrap gap-2">
                        <p>Subscription plan:</p>
                        <RadioGroup.Root
                            className="flex flex-wrap gap-x-4 gap-y-1.5"
                            defaultValue="threat"
                            value={plan}
                            onValueChange={value => setPlan(value as Plan)}
                            aria-label="Your verdict"
                            required
                        >
                            {Object.entries(Plan).map(([key, value]) => (
                                <RadioGroup.Item
                                    key={key}
                                    value={value}
                                    asChild={true}
                                >
                                    <label className="flex items-center gap-1.5">
                                        <input
                                            type="radio"
                                            name="verdict"
                                            value={value}
                                            checked={value === plan}
                                        />
                                        {key}
                                    </label>
                                </RadioGroup.Item>
                            ))}
                        </RadioGroup.Root>
                    </div>
                </div>
                {lookupResults && (
                    <div className="prose w-full max-w-full relative">
                        <HighlightCode
                            className={classNames(
                                'overflow-auto mb-0',
                                plan === Plan.Core && 'h-[250px]',
                                plan === Plan.Professional && 'h-[550px]',
                                plan === Plan.Ultimate && 'h-[770px]'
                            )}
                            code={isLoading ? 'Loading...' : lookupResults}
                            language="json"
                        />
                        {lookupResults && !isLoading && (
                            <Link
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                    path: `${
                                        PATHS.THREAT_LOOKUP
                                    }/${encodeURIComponent(lookupIoc)}`
                                })}
                                className="absolute right-0 top-0 mt-4 mr-4"
                            >
                                <Button theme={ButtonTheme.White}>
                                    Decoded format
                                </Button>
                            </Link>
                        )}
                    </div>
                )}
                {isError && (
                    <div className="flex justify-center">
                        <p className="text-red-500">
                            Something went wrong. Please try again later.
                        </p>
                    </div>
                )}
            </form>
        </section>
    );
};

export default ThreatIntelligenceApi;
