import React, { memo } from 'react';

const OurDataSources = () => {
    return (
        <div className="grid w-full max-w-screen-xl grid-cols-1 gap-8 sm:gap-16 md:grid-cols-3">
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">OSINT</h3>
                <p className="text-gray-600">
                    We collect indicators of compromise (IoCs) and threat data
                    from open sources, combining details from nearly all
                    available public sources into one centralized location.
                </p>
            </div>
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">
                    Honeypots/sensors
                </h3>
                <p className="text-gray-600">
                    We operate a custom network of honeypots to trap and study
                    attacks's infrastructure.
                </p>
            </div>
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">
                    Algorithmic and Machine Learning Analysis
                </h3>
                <p className="text-gray-700">
                    We use known IoCs to predict and discover new potential
                    threats, which we then validate and incorporate into our
                    database.
                </p>
            </div>
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">
                    Abuse Reports
                </h3>
                <p className="text-gray-600">
                    We gather abuse reports and scrutinize them for insights.
                </p>
            </div>
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">
                    In-House Research
                </h3>
                <p className="text-gray-600">
                    Our specialist team conducts independent research to
                    pinpoint and dissect new IoCs.
                </p>
            </div>
            <div>
                <h3 className="mb-2 text-xl font-bold text-gray-700">
                    Own Data Enrichments
                </h3>
                <p className="text-gray-600">
                    We continuously collect, process, and unify essential
                    enrichment data, including IP Geolocation, Proxy Detection,
                    Reverse DNS (PTR), AS Information, WHOIS (IP Netblock)
                    Information, and more.
                </p>
            </div>
        </div>
    );
};

export default memo(OurDataSources);
