import LookupForm from '@/entities/Lookup/ui/LookupForm/LookupForm';
import React from 'react';

const TryItNow = () => {
    return (
        <section className="w-full max-w-screen-lg">
            <div className="w-full relative isolate overflow-hidden bg-gray-900 shadow-2xl rounded-3xl px-6 py-16 md:py-16 lg:px-20">
                <svg
                    viewBox="0 0 1024 1024"
                    className="absolute top-1/4 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:translate-y-0 lg:-translate-x-1/2"
                    aria-hidden="true"
                >
                    <circle
                        cx="512"
                        cy="512"
                        r="512"
                        fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                        fillOpacity="0.7"
                    />
                    <defs>
                        <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                            <stop stopColor="#7775D6" />
                            <stop offset="1" stopColor="#E935C1" />
                        </radialGradient>
                    </defs>
                </svg>
                <div className="flex flex-col gap-12">
                    <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                        Experience our data in action with a live IP Lookup
                    </h2>
                    <LookupForm examplesClassName="text-white" />
                </div>
            </div>
        </section>
    );
};

export default TryItNow;
