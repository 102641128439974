import { AppLink } from '@/shared/ui/AppLink';
import { composeLink } from '@/shared/lib/links';
import { PATHS } from '@/shared/lib/constants';
import Logo, { LOGO } from '@/shared/ui/Logo/Logo';

const Footer = () => {
    return (
        <footer className="p-4 bg-fs-primary-700 sm:pt-12 sm:pb-8">
            <div className="mx-auto max-w-screen-xl">
                <div className="md:flex md:justify-between flex-wrap gap-8">
                    <div className="mb-6 md:mb-0">
                        <Logo type={LOGO.LONG_TRANSPARENT_WHITE} />
                    </div>
                    <div className="max-lg:w-full flex flex-wrap gap-y-8 gap-x-16 justify-between mt-3">
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase">
                                Products
                            </h2>
                            <ul className="text-white">
                                <li className="mb-4">
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.THREAT_DATABASE
                                        })}
                                        className="text-white font-normal"
                                    >
                                        Threat Intelligence Data Feed
                                    </AppLink>
                                </li>
                                <li className="mb-4">
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.THREAT_API
                                        })}
                                        className="text-white font-normal"
                                    >
                                        Threat Intelligence API
                                    </AppLink>
                                </li>
                                <li>
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.THREAT_LOOKUP
                                        })}
                                        className="text-white font-normal"
                                    >
                                        Threat Intelligence Lookup
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase">
                                Resources
                            </h2>
                            <ul className="text-gray-300">
                                <li className="mb-4">
                                    <AppLink
                                        href="https://docs.falconsentinel.com/data-feed"
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        Data Feed Documentation
                                    </AppLink>
                                </li>
                                <li className="mb-4">
                                    <AppLink
                                        href="https://docs.falconsentinel.com/api"
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        API Documentation
                                    </AppLink>
                                </li>
                                <li className="mb-4">
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.PRIVACY_POLICY
                                        })}
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        FAQ
                                    </AppLink>
                                </li>
                                <li>
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.THREAT_STATISTICS
                                        })}
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        Statistics
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="mb-6 text-sm font-bold text-white uppercase">
                                Company
                            </h2>
                            <ul className="text-gray-300">
                                <li className="mb-4">
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.ABOUT_US
                                        })}
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        About Us
                                    </AppLink>
                                </li>
                                <li className="mb-4">
                                    <AppLink
                                        href={composeLink({
                                            path: PATHS.CONTACT_SALES
                                        })}
                                        className="text-white font-normal"
                                        size="lg"
                                    >
                                        Contact Us
                                    </AppLink>
                                </li>
                                <li className="mb-4">
                                    <AppLink
                                        href="https://linkedin.com/company/falconsentinel"
                                        className="text-white font-normal"
                                        target="_blank"
                                        size="lg"
                                    >
                                        LinkedIn
                                    </AppLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr className="my-6 sm:mx-auto border-gray-300 lg:my-8" />
                <div className="sm:flex sm:justify-between">
                    <div className="sm:flex sm:flex-col">
                        <p className="text-sm text-white">
                            CodeLine Oy © 2018 — 2024
                        </p>
                        <p className="text-sm text-white">
                            Business ID: FI2915202-4. Hämeentie 31, Helsinki,
                            Finland.
                        </p>
                    </div>
                    <div className="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                        <AppLink
                            href={composeLink({
                                path: PATHS.PRIVACY_POLICY
                            })}
                            className="text-white font-normal"
                            size="lg"
                        >
                            Privacy Policy
                        </AppLink>
                        <AppLink
                            href={composeLink({
                                path: PATHS.TERMS_OF_SERVICE
                            })}
                            className="text-white font-normal"
                            size="lg"
                        >
                            Terms of Service
                        </AppLink>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
