import { Button, ButtonSize, ButtonTheme } from '@/shared/ui/Button';
import React, { useCallback, useMemo, useState } from 'react';
import { useUserSessionStripeId } from '@/shared/hooks/use-user-session-stripe-id';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Switch } from '@/shared/ui/Switch';

interface PricingProps {
    className?: string;
}

const priceIDs = {
    monthly: {
        core: process.env.NEXT_PUBLIC_TIAPI_CORE_PRICE_MONTHLY_ID as string,
        professional: process.env
            .NEXT_PUBLIC_TIAPI_PROFESSIONAL_PRICE_MONTHLY_ID as string,
        ultimate: process.env
            .NEXT_PUBLIC_TIAPI_ULTIMATE_PRICE_MONTHLY_ID as string
    },
    yearly: {
        core: process.env.NEXT_PUBLIC_TIAPI_CORE_PRICE_YEARLY_ID as string,
        professional: process.env
            .NEXT_PUBLIC_TIAPI_PROFESSIONAL_PRICE_YEARLY_ID as string,
        ultimate: process.env
            .NEXT_PUBLIC_TIAPI_ULTIMATE_PRICE_YEARLY_ID as string
    }
};

const costs = {
    monthly: {
        core: '$390',
        professional: '$990',
        ultimate: '$2,900'
    },
    yearly: {
        core: '$3,900',
        professional: '$9,900',
        ultimate: '$29,000'
    }
};

const Pricing = (props: PricingProps) => {
    const [annualBilling, setAnnualBilling] = useState(false);
    const userStripeCustomerId = useUserSessionStripeId();

    const period = useMemo(
        () => (annualBilling ? 'yearly' : 'monthly'),
        [annualBilling]
    );
    const periodText = useMemo(
        () => (annualBilling ? 'yr' : 'mo'),
        [annualBilling]
    );

    const generatePriceLink = useCallback(
        (priceID: string) => {
            return `/api/stripe/checkout?priceId=${priceID}&customerId=${
                userStripeCustomerId || ''
            }`;
        },
        [userStripeCustomerId]
    );

    const coreLink = useMemo(
        () => generatePriceLink(priceIDs[period].core),
        [generatePriceLink, period]
    );
    const professionalLink = useMemo(
        () => generatePriceLink(priceIDs[period].professional),
        [generatePriceLink, period]
    );
    const ultimateLink = useMemo(
        () => generatePriceLink(priceIDs[period].ultimate),
        [generatePriceLink, period]
    );

    const coreCost = useMemo(() => costs[period].core, [period]);
    const professionalCost = useMemo(
        () => costs[period].professional,
        [period]
    );
    const ultimateCost = useMemo(() => costs[period].ultimate, [period]);

    return (
        <section id="pricing" className="w-full max-w-screen-xl">
            <div className="flex flex-col items-center gap-12 md:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Pricing plans
                </h2>

                <div className="relative mx-auto flex max-w-fit items-center space-x-2">
                    <span className="text-gray-500">Billed Monthly</span>
                    <Switch
                        fn={setAnnualBilling}
                        trackDimensions="h-6 w-12"
                        thumbDimensions="h-5 w-5"
                        thumbTranslate="translate-x-6"
                        checked={annualBilling}
                    />
                    <span className="text-gray-500">Billed Annually</span>
                    <span className="absolute -top-8 -right-12 rounded-full bg-purple-200 px-3 py-1 text-sm text-purple-700 sm:-right-[9.5rem] sm:-top-2">
                        🎁 2 months FREE
                    </span>
                </div>

                <div className="w-full rounded-xl border border-gray-200 bg-white shadow-md overflow-auto">
                    <table className="pricing-table max-md:block max-md:whitespace-nowrap max-md:overflow-scroll">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Core</th>
                                <th>Professional</th>
                                <th>Ultimate</th>
                                <th>Enterprise</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Requests per day</strong>
                                </td>
                                <td>100</td>
                                <td>500</td>
                                <td>2,500</td>
                                <td>Custom</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Request per minute</strong>
                                </td>
                                <td>1</td>
                                <td>5</td>
                                <td>60</td>
                                <td>Custom</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>"realTimeData" flag allowed</strong>
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Output fields</strong>
                                </td>
                                <td>
                                    <p>
                                        <strong>Core</strong> fields:
                                    </p>
                                    <ul>
                                        <li>Verdict</li>
                                        <li>Threat Score</li>
                                        <li>Threat Type</li>
                                        <li>First/Last Seen</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>
                                        All from <strong>Core</strong>, plus:
                                    </p>
                                    <ul>
                                        <li>Benign Infrastructure Info</li>
                                        <li>Known IPs Info</li>
                                        <li>IP Geolocation Info</li>
                                        <li>Tor Exit Node flag</li>
                                        <li>Public VPN Info</li>
                                        <li>PTR Record Info</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>
                                        All from <strong>Professional</strong>,
                                        plus:
                                    </p>
                                    <ul>
                                        <li>ASN Info</li>
                                        <li>IP Netblock Score</li>
                                        <li>WHOIS (Netblock) Info</li>
                                    </ul>
                                </td>
                                <td>
                                    <p>
                                        All from <strong>Ultimate</strong>,
                                        plus:
                                    </p>
                                    <ul className="whitespace-normal">
                                        <li>
                                            Custom data enrichments (upon
                                            request)
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Dedicated support</strong>
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Custom output formats</strong>
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Custom data enrichments</strong>
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        className="w-5 h-5 text-red-700"
                                        aria-hidden
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        className="w-5 h-5 text-green-700"
                                        aria-hidden
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <strong>Price</strong>
                                </td>
                                <td>
                                    <strong>
                                        {coreCost}
                                        {'/'}
                                        {periodText}
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        {professionalCost}
                                        {'/'}
                                        {periodText}
                                    </strong>
                                </td>
                                <td>
                                    <strong>
                                        {ultimateCost}
                                        {'/'}
                                        {periodText}
                                    </strong>
                                </td>
                                <td>
                                    <strong>Custom</strong>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <a
                                        href={coreLink}
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        <Button
                                            className="w-full"
                                            size={ButtonSize.Medium}
                                            theme={ButtonTheme.Outline}
                                        >
                                            Buy Core
                                        </Button>
                                    </a>
                                </td>
                                <td>
                                    <a
                                        href={professionalLink}
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        <Button
                                            className="w-full"
                                            size={ButtonSize.Medium}
                                        >
                                            Buy Professional
                                        </Button>
                                    </a>
                                </td>
                                <td>
                                    <a
                                        href={ultimateLink}
                                        rel="nofollow"
                                        target="_blank"
                                    >
                                        <Button
                                            className="w-full"
                                            size={ButtonSize.Medium}
                                            theme={ButtonTheme.Outline}
                                        >
                                            Buy Ultimate
                                        </Button>
                                    </a>
                                </td>
                                <td>
                                    <a href={'#contact-us'}>
                                        <Button
                                            className="w-full"
                                            size={ButtonSize.Medium}
                                            theme={ButtonTheme.Outline}
                                        >
                                            Contact us
                                        </Button>
                                    </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Please note that our services are exclusively for registered
                    companies. Orders from private individuals or emails that do
                    not match the company domain name will not be processed.
                </p>

                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    For invoice payments, please get in touch with us via{' '}
                    <a
                        href="mailto:support@falconsentinel.com"
                        className="text-fs-primary underline"
                    >
                        support@falconsentinel.com
                    </a>{' '}
                    or{' '}
                    <a
                        href={'#contact-us'}
                        className="text-fs-primary underline"
                    >
                        Contact us form
                    </a>
                    .
                </p>
            </div>
        </section>
    );
};

export default Pricing;
