import React from 'react';

import { ContactUsForm } from '@/widgets/ContactUsForm';
import { TERMS_OF_SERVICE } from '@/shared/lib/texts';

interface ContactUsProps {
    className?: string;
}

const ContactUsSection = (props: ContactUsProps) => {
    return (
        <section id="contact-us">
            <div className="flex flex-col items-center gap-12 lg:gap-16 pt-8 lg:pt-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Contact Us
                </h2>
                <p className="max-w-6xl mx-auto text-center text-gray-500 md:text-xl">
                    Have a technical issue? Want to provide feedback? Need
                    information about our subscription plans? We're here to
                    help! Please note that our services are exclusively for
                    registered companies. Requests from private individuals or
                    emails that do not match the company domain name will not be
                    processed. You can also reach us at{' '}
                    <a
                        href="mailto:support@falconsentinel.com"
                        className="text-fs-primary underline"
                    >
                        support@falconsentinel.com
                    </a>
                    .
                </p>
                <ContactUsForm />
            </div>
        </section>
    );
};

export default ContactUsSection;
