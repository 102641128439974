// @ts-nocheck
import React, { useEffect, useState } from 'react';
import {
    attackRecords as getAttackRecords,
    c2Records as getC2Records,
    malwareRecords as getMalwareRecords,
    phishingRecords as getPhishingRecords,
    RecordsRawResponse,
    spamRecords as getSpamRecords,
    suspiciousRecords as getSuspiciousRecords
} from '@/shared/lib/statistics';
import { EChartOption } from 'echarts';
import StatisticsBarChart from '@/entities/TIDFStatistics/ui/StatisticsBarChart/StatisticsBarChart';
import dateFormat from 'dateformat';

const ThreatStatistics = () => {
    const [attackRecords, setAttackRecords] = useState<RecordsRawResponse[]>();
    const [c2Records, setC2Records] = useState<RecordsRawResponse[]>();
    const [malwareRecords, setMalwareRecords] =
        useState<RecordsRawResponse[]>();
    const [phishingRecords, setPhishingRecords] =
        useState<RecordsRawResponse[]>();
    const [spamRecords, setSpamRecords] = useState<RecordsRawResponse[]>();
    const [suspiciousRecords, setSuspiciousRecords] =
        useState<RecordsRawResponse[]>();

    const [categories, setCategories] = useState<string[]>([]);
    const [attackRecordsSeries, setAttackRecordsSeries] =
        useState<EChartOption['series']>();
    const [c2RecordsSeries, setC2RecordsSeries] =
        useState<EChartOption['series']>();
    const [malwareRecordsSeries, setMalwareRecordsSeries] =
        useState<EChartOption['series']>();
    const [phishingRecordsSeries, setPhishingRecordsSeries] =
        useState<EChartOption['series']>();
    const [spamRecordsSeries, setSpamRecordsSeries] =
        useState<EChartOption['series']>();
    const [suspiciousRecordsSeries, setSuspiciousRecordsSeries] =
        useState<EChartOption['series']>();

    useEffect(() => {
        const calls = [
            [getAttackRecords, setAttackRecords],
            [getC2Records, setC2Records],
            [getMalwareRecords, setMalwareRecords],
            [getPhishingRecords, setPhishingRecords],
            [getSpamRecords, setSpamRecords],
            [getSuspiciousRecords, setSuspiciousRecords]
        ];

        calls.forEach(([fn, setter]) => {
            fn().then(setter);
        });
    }, []);

    useEffect(() => {
        if (!attackRecords) return;
        setCategories(
            attackRecords?.map(record =>
                dateFormat(record.date * 1000, 'mmmm d, yyyy')
            )
        );
    }, [attackRecords]);

    useEffect(() => {
        if (!attackRecords) return;
        setAttackRecordsSeries({
            name: 'Attack threats',
            type: 'bar',
            stack: 'threats',
            data: attackRecords?.map(record => record.value)
        });
    }, [attackRecords]);

    useEffect(() => {
        if (!c2Records) return;
        setC2RecordsSeries({
            name: 'C2 threats',
            type: 'bar',
            stack: 'threats',
            data: c2Records?.map(record => record.value)
        });
    }, [c2Records]);

    useEffect(() => {
        if (!malwareRecords) return;
        setMalwareRecordsSeries({
            name: 'Malware threats',
            type: 'bar',
            stack: 'threats',
            data: malwareRecords?.map(record => record.value)
        });
    }, [malwareRecords]);

    useEffect(() => {
        if (!phishingRecords) return;
        setPhishingRecordsSeries({
            name: 'Phishing threats',
            type: 'bar',
            stack: 'threats',
            data: phishingRecords?.map(record => record.value)
        });
    }, [phishingRecords]);

    useEffect(() => {
        if (!spamRecords) return;
        setSpamRecordsSeries({
            name: 'Spam threats',
            type: 'bar',
            stack: 'threats',
            data: spamRecords?.map(record => record.value)
        });
    }, [spamRecords]);

    useEffect(() => {
        if (!suspiciousRecords) return;
        setSuspiciousRecordsSeries({
            name: 'Suspicious threats',
            type: 'bar',
            stack: 'threats',
            data: suspiciousRecords?.map(record => record.value)
        });
    }, [suspiciousRecords]);

    return (
        <section className="w-full max-w-screen-xl min-h-[36rem]">
            <div className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-white shadow-md max-sm:py-2 xs:p-4 sm:p-8">
                {attackRecordsSeries &&
                c2RecordsSeries &&
                malwareRecordsSeries &&
                phishingRecordsSeries &&
                spamRecordsSeries &&
                suspiciousRecordsSeries ? (
                    <StatisticsBarChart
                        categories={categories}
                        series={[
                            attackRecordsSeries,
                            c2RecordsSeries,
                            phishingRecordsSeries,
                            malwareRecordsSeries,
                            spamRecordsSeries,
                            suspiciousRecordsSeries
                        ]}
                    />
                ) : (
                    <div className="flex justify-center items-center">
                        <span className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></span>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ThreatStatistics;
