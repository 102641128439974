import Layout from '@/widgets/Layout/ui/Layout';
import { AppHead } from '@/shared/ui/AppHead';
import React from 'react';
import { ContactUsSection } from '@/widgets/ContactUsSection';
import { ProductCards } from '@/widgets/ProductCards';
import LookupForm from '@/entities/Lookup/ui/LookupForm/LookupForm';
import { GetServerSideProps } from 'next';
import { getTestIps } from '@/entities/Lookup/api/v1Test';
import { TestIPs } from '@/entities/Lookup/model/types/lookup';

export const DESCRIPTION =
    "Web tool designed for security engineers to quickly evaluate suspicious IPs directly through our website's interface. It delivers a comprehensive security assessment, outlines potential threats, and provides threat intelligence.";

interface ThreatLookupPageProps {
    ips: TestIPs;
}

export default function ThreatLookupDemoPage(props: ThreatLookupPageProps) {
    const { ips } = props;

    return (
        <Layout>
            <AppHead
                title="Threat Intelligence Lookup | FalconSentinel"
                description={DESCRIPTION}
            />
            <div className="w-full flex flex-col items-center gap-12 sm:gap-16 px-5 xl:px-0">
                <div className="max-w-7xl w-full flex gap-8 max-lg:flex-col lg:mt-[-40px]">
                    <div className="w-full flex flex-col gap-12">
                        <h1 className="max-w-5xl text-gray-800 bg-clip-text text-4xl font-bold tracking-[-0.02em] md:text-6xl">
                            Threat Intelligence Lookup
                        </h1>
                        <div className="prose">
                            <ul>
                                <li>
                                    Designed for security engineers to assess
                                    IPv4/IPv6 addresses
                                </li>
                                <li>
                                    Outlines potential threats, provides deep,
                                    up-to-date threat intelligence
                                </li>
                                <li>
                                    Advanced innovative algorithm with
                                    comprehensive coverage, low false positives
                                </li>
                                <li>
                                    Options for Web Lookup, API, and Data Feed
                                    access
                                </li>
                                <li>Quick 50ms response time per request</li>
                                <li>
                                    Built on our Sensor Network, OSINT, In-House
                                    Research, ML Analysis
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="w-full h-fit overflow-hidden rounded-xl border border-fs-primary bg-white shadow-md p-4 sm:p-8 sm:pb-6">
                        <LookupForm ips={ips} />
                    </div>
                </div>
                <ProductCards />
                <ContactUsSection />
            </div>
        </Layout>
    );
}

export const getServerSideProps: GetServerSideProps = async context => {
    const lookupFormProps = await getTestIps();

    return {
        props: {
            ...lookupFormProps
        }
    };
};
