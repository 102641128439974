import React, { memo } from 'react';

const WeCoverThreatTypes = () => {
    return (
        <section>
            <div className="flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    We cover the following threat types
                </h2>
                <div className="grid w-full max-w-screen-xl grid-cols-1 gap-8 sm:gap-16 md:grid-cols-3">
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            Attack
                        </h3>
                        <p className="text-gray-600">
                            Malicious activity detected from the host.
                        </p>
                    </div>
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            C2 (C&C)
                        </h3>
                        <p className="text-gray-700">
                            Known botnet's "Command and Control" hosts.
                        </p>
                    </div>
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            Malware
                        </h3>
                        <p className="text-gray-600">
                            Hosts related to malicious software distribution.
                        </p>
                    </div>
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            Phishing
                        </h3>
                        <p className="text-gray-600">
                            Hosts involved in phishing activity.
                        </p>
                    </div>
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            Spam
                        </h3>
                        <p className="text-gray-700">
                            Hosts engaged in sending spam.
                        </p>
                    </div>
                    <div>
                        <h3 className="mb-2 text-xl font-bold text-gray-700">
                            Suspicious
                        </h3>
                        <p className="text-gray-600">
                            Hosts whose activity hasn't been confirmed as
                            malicious but remains suspicious. For example, it
                            could involve hosts scraping websites or sending a
                            large number of ICMP queries.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default memo(WeCoverThreatTypes);
