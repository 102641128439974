import { useCallback, useEffect, useState } from 'react';

export default function useHasScrolled(threshold: number = 50) {
    const [hasScrolled, setHasScrolled] = useState(false);

    const onScroll = useCallback(() => {
        setHasScrolled(window.pageYOffset > threshold);
    }, [threshold]);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [onScroll]);

    return hasScrolled;
}
