export enum IocType {
    IP = 'ip',
    CIDR = 'cidr',
    DOMAIN = 'domain',
    URL = 'url'
}

enum DistributionType {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly'
}

export enum ThreatType {
    ATTACK = 'attack',
    BOTNET = 'botnet',
    C2 = 'c2',
    MALWARE = 'malware',
    PHISHING = 'phishing',
    SPAM = 'spam',
    SUSPICIOUS = 'suspicious',
    TOR = 'tor',
    VPN = 'vpn',
    GENERIC = 'generic'
}

export const ThreatTypeName: Record<ThreatType, string> = {
    [ThreatType.ATTACK]: 'Attack',
    [ThreatType.MALWARE]: 'Malware',
    [ThreatType.BOTNET]: 'Botnet',
    [ThreatType.C2]: 'C2',
    [ThreatType.PHISHING]: 'Phishing',
    [ThreatType.SPAM]: 'Spam',
    [ThreatType.TOR]: 'Tor',
    [ThreatType.SUSPICIOUS]: 'Suspicious',
    [ThreatType.GENERIC]: 'Generic',
    [ThreatType.VPN]: 'VPN'
};

export const IoCTypeName: Record<IocType, string> = {
    [IocType.CIDR]: 'CIDR',
    [IocType.DOMAIN]: 'Domain',
    [IocType.URL]: 'URL',
    [IocType.IP]: 'IP'
};

export const ThreatTypeDescription: Record<ThreatType, string> = {
    [ThreatType.ATTACK]:
        'Attack: malicious activity detected from the host. For example, SSH brute-force, etc. Read more <a href="https://docs.threatintelligenceplatform.com/api/v1/lookup/attack" target="_blank">here</a>.',
    [ThreatType.MALWARE]:
        'Malware: the IoC is related to malicious software distribution. It can be a host or a URL serving the malware.',
    [ThreatType.BOTNET]:
        'Botnet: a host was detected as an actor in a group of connected hosts that perform malicious activities (botnet). Read more <a class="underline" href="https://falconsentinel.com/faq/what-is-botnet" target="_blank">here</a>.',
    [ThreatType.C2]:
        'C2 or C&C: the host is a known botnet\'s "Command and Control" server.',
    [ThreatType.PHISHING]:
        'Phishing: the indicator, usually a domain name or URL, is involved in Phishing activity.',
    [ThreatType.SPAM]:
        'Spam: a host engaged in sending spam. Review <a class="underline" href="https://falconsentinel.com/faq/why-tor-exit-nodes-may-be-dangerous" target="_blank">4 reasons</a> why spam is dangerous.',
    [ThreatType.TOR]:
        'Tor: a host acts as a TOR exit node. Read more <a class="underline" href="https://falconsentinel.com/faq/why-tor-exit-nodes-may-be-dangerous" target="_blank">here</a> why TOR exit nodes may be dangerous?',
    [ThreatType.SUSPICIOUS]:
        "Suspicious: IoC's activity hasn't been verified to be of malicious nature. For instance, it may be a host scraping websites, sending large amounts of ICMP queries, etc.",
    [ThreatType.GENERIC]:
        "Generic: IoC has been involved in some form of malicious activity but couldn't be classified into one of the other categories.",
    [ThreatType.VPN]: 'VPN'
};

export const IoCTypeDescription: Record<IocType, string> = {
    [IocType.CIDR]:
        'CIDR is a Classless Inter-Domain Routing notation for IP addresses that enables more effective use of IP addresses and routing. A CIDR notation has an IP address followed by a slash and the number of bits of the network prefix, such as 192.0.2.0 / 24. A CIDR notation can also show a range of IP addresses, such as 66.10.5.0 / 27, which covers 32 IP addresses from 66.10.5.0 to 66.10.5.31.',
    [IocType.DOMAIN]: 'Harmful or malicious domain names.',
    [IocType.URL]:
        'Harmful or malicious URLs that may be used for phishing attacks, drive-by downloads, or other web-based threats. Includes both full and partial URLs.',
    [IocType.IP]:
        'Harmful or known malicious IP addresses linked to malicious actors, command and control (C2) servers or botnets.'
};

interface RecordsRawParams {
    from?: number;
    to?: number;
    threatType?: ThreatType;
    iocType?: IocType;
    distributionType?: DistributionType;
}

export interface RecordsRawResponse {
    date: number;
    value: number;
}

export async function recordsRaw(
    params: RecordsRawParams = {}
): Promise<RecordsRawResponse[]> {
    const offsetDays = 14;
    const now = new Date();
    const startDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - offsetDays + 1
    ).setUTCHours(0, 0, 0, 0);
    const endDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
    ).setUTCHours(23, 59, 59, 999);
    const {
        from = Math.floor(startDate / 1000),
        to = Math.floor(endDate / 1000),
        threatType,
        iocType,
        distributionType = DistributionType.DAILY
    } = params;

    const query = new URLSearchParams({
        from: from.toString(),
        to: to.toString(),
        threatType: threatType ?? '',
        iocType: iocType ?? '',
        distributionType: distributionType ?? ''
    });

    return await fetch(`/api/statistics?${query}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            throw new Error('Failed to fetch statistics');
        })
        .catch(() => {
            console.log('Error fetching statistics');
            return [];
        });
}

export async function cidrIoCRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, iocType: IocType.CIDR });
}

export async function domainIoCRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, iocType: IocType.DOMAIN });
}

export async function ipIoCRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, iocType: IocType.IP });
}

export async function urlIoCRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, iocType: IocType.URL });
}

export async function attackRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.ATTACK });
}

export async function botnetRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.BOTNET });
}

export async function c2Records(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.C2 });
}

export async function genericRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.GENERIC });
}

export async function malwareRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.MALWARE });
}

export async function phishingRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.PHISHING });
}

export async function spamRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.SPAM });
}

export async function suspiciousRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.SUSPICIOUS });
}

export async function torRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.TOR });
}

export async function vpnRecords(params: RecordsRawParams = {}) {
    return recordsRaw({ ...params, threatType: ThreatType.VPN });
}

export async function getCachedStatistics() {
    return fetch('/api/statistics/cached')
        .then(response => {
            if (response.ok) {
                return response.json();
            }

            throw new Error('Failed to fetch statistics');
        })
        .catch(() => {
            console.log('Error fetching statistics');
            return null;
        });
}
