import React from 'react';
import { createPortal } from 'react-dom';
import { safeDocument } from '@/shared/lib/constants';

interface PortalProps {
    children: React.ReactNode;
    container?: HTMLElement;
}

const Portal = (props: PortalProps): JSX.Element => {
    const { children, container = safeDocument?.body } = props;

    if (!container) {
        return children as JSX.Element;
    }

    return createPortal(children, container);
};

export default Portal;
