import { ReactNode, useMemo } from 'react';
import { Footer } from '@/widgets/Footer';
import { Header } from '@/widgets/Header';
import {
    useLayout,
    Layout as LayoutType
} from '@/app/providers/LayoutProvider';
import classNames from 'classnames';

export default function Layout({
    children
}: {
    meta?: {
        title?: string;
        description?: string;
        image?: string;
    };
    children: ReactNode;
}) {
    const { layout } = useLayout();

    const bodyClassName = useMemo(() => {
        let className = classNames('flex flex-col min-w-screen min-h-screen');

        if (layout === LayoutType.Default) {
            className = classNames(className, 'fs-gradient');
        }

        return className;
    }, [layout]);

    const mainClassName = useMemo(() => {
        let className = classNames(
            'flex flex-1 flex-col items-center justify-start pt-32 lg:pt-52 py-12 lg:py-28'
        );

        return className;
    }, [layout]);

    return (
        <div className={bodyClassName}>
            <Header />
            <main className={mainClassName}>{children}</main>
            <Footer />
        </div>
    );
}
