import {useMemo} from "react";
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/night-owl.css';

hljs.registerLanguage('json', json)

interface HighlightCodeProps {
    className?: string;
    code: string | object;
    language?: 'json';
    noTheme?: boolean;
}

const HighlightCode = (props: HighlightCodeProps) => {
    const { className, code, language = 'json', noTheme = false } = props;

    const codeString = typeof code === 'object' ? JSON.stringify(code, null, 2) : code;
    const highlightedCode = useMemo(() =>
        noTheme ?
            codeString :
            hljs.highlight(codeString, {language}).value,
        [code]
    );

    return (
        <pre
            className={className}
            dangerouslySetInnerHTML={{
                __html: highlightedCode,
            }}
        />
    );
};

export default HighlightCode;
