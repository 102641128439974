import React, { useEffect, useRef } from 'react';
import ReactECharts, { EChartsInstance } from 'echarts-for-react';
import { EChartOption } from 'echarts';

interface StatisticsLineChartProps {
    header?: string;
    categories: string[];
    series: EChartOption['series'] | undefined;
}

const StatisticsBarChart = (props: StatisticsLineChartProps) => {
    const chart = useRef<EChartsInstance>(null);
    const options: EChartOption = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        legend: {},
        grid: { top: 50, right: 26, bottom: 8, left: 8, containLabel: true },
        xAxis: [
            {
                type: 'category',
                data: props.categories
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: props.series
    };

    useEffect(() => {
        if (!chart.current) return;
        window.addEventListener('resize', () => {
            chart.current.resize();
        });

        return () => {
            window.removeEventListener('resize', () => {
                chart.current.resize();
            });
        };
    }, [chart]);

    return (
        <div className="w-full">
            {props.header && (
                <h2 className="text-2xl font-bold mb-4">{props.header}</h2>
            )}
            <ReactECharts
                ref={chart}
                option={options}
                style={{ height: '500px', width: '100%' }}
            />
        </div>
    );
};

export default StatisticsBarChart;
