import React, { memo } from 'react';
import { Card } from '@/widgets/Card';

const UseCases = () => {
    return (
        <section>
            <div className="flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Use cases
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Enhance your organization's cybersecurity efforts:
                </p>
                <div className="grid w-full max-w-screen-xl grid-cols-1 gap-8 md:grid-cols-3">
                    <Card
                        title="Automated Threat Detection"
                        description="Integrate our threat intelligence Data Feeds and APIs to automatically detect and flag indicators of compromise (IoCs)."
                    />
                    <Card
                        title="Enrichment of Security Data"
                        description="Use our threat intelligence to enrich alerts and incidents with contextual data, helping security analysts to understand the nature and severity of threats, prioritize and respond to threats more effectively."
                    />
                    <Card
                        title="Integration with SIEM & SOAR"
                        description="Integrate with Security Information and Event Management (SIEM) and Security Orchestration, Automation, and Response (SOAR) systems to streamline security operations and improve incident response."
                    />
                </div>
            </div>
        </section>
    );
};

export default memo(UseCases);
