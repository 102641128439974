import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useScrollLock } from 'usehooks-ts';
import Link from 'next/link';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Portal from '@/shared/ui/Portal';
import Logo, { LOGO } from '@/shared/ui/Logo/Logo';

interface BurgerMenuProps {
    className?: string;
}

interface BurgerProps {
    className?: string;
    open: boolean;
    toggleMenu: () => void;
}

const Burger = (props: BurgerProps) => {
    const { className, open, toggleMenu } = props;
    return (
        <div
            className={classNames(
                'tham-e-squeeze tham tham-w-6',
                'z-[100] cursor-pointer',
                {
                    'tham-active': open
                },
                className
            )}
            onClick={toggleMenu}
        >
            <div className="tham-box">
                <div className="tham-inner bg-white" />
            </div>
        </div>
    );
};

const BurgerMenu = (props: BurgerMenuProps) => {
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const { data: session, status } = useSession();
    const { pathname } = useRouter();
    const { lock, unlock } = useScrollLock({
        autoLock: false
    });

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    useEffect(() => {
        console.log('open', open);
        if (open) {
            lock();
        } else {
            unlock();
        }
    }, [open]);

    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        <div className={className}>
            <Burger open={open} toggleMenu={toggleMenu} />
            <Portal>
                <div
                    className={classNames(
                        'fixed top-0 left-0 w-full h-full bg-white/50 backdrop-blur-lg',
                        'flex flex-col items-center justify-center',
                        'transition-all duration-300',
                        'z-50',
                        {
                            'opacity-100 pointer-events-auto': open
                        },
                        {
                            'opacity-0 pointer-events-none': !open
                        }
                    )}
                >
                    <Burger
                        className="absolute top-[24px] right-[20px]"
                        open={open}
                        toggleMenu={toggleMenu}
                    />
                    <div className="flex flex-col items-center justify-center gap-4">
                        <Logo type={LOGO.LONG_TRANSPARENT_BLACK} />
                        <hr className="w-full border-gray-200" />
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.THREAT_DATABASE
                            })}
                            className="text-xl font-medium"
                        >
                            Threat Intelligence Data Feed
                        </Link>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.THREAT_API
                            })}
                            className="text-xl font-medium"
                        >
                            Threat Intelligence API
                        </Link>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.THREAT_LOOKUP
                            })}
                            className="text-xl font-medium"
                        >
                            Threat Intelligence Lookup
                        </Link>
                        <hr className="w-full border-gray-200" />
                        <Link
                            href="https://docs.falconsentinel.com/data-feed"
                            className="text-xl font-medium"
                        >
                            Documentation
                        </Link>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.FAQ
                            })}
                            className="text-xl font-medium"
                        >
                            FAQ
                        </Link>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.THREAT_STATISTICS
                            })}
                            className="text-xl font-medium"
                        >
                            Statistics
                        </Link>
                        <Link
                            href={composeLink({ path: PATHS.ABOUT_US })}
                            className="text-xl font-medium"
                        >
                            About Us
                        </Link>
                        <hr className="w-full border-gray-200" />
                        <Link
                            href={composeLink({
                                path: PATHS.CONTACT_SALES
                            })}
                            className="text-xl font-medium"
                        >
                            Contact us
                        </Link>
                    </div>
                </div>
            </Portal>
        </div>
    );
};

export default BurgerMenu;
