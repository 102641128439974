import useHasScrolled from '@/shared/hooks/use-has-scrolled';
import Link from 'next/link';
import React, { useMemo } from 'react';
import { AppLink } from '@/shared/ui/AppLink';
import { useSession } from 'next-auth/react';
import classNames from 'classnames';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { ProductsMenu } from '@/widgets/ProductsMenu';
import { BurgerMenu } from '@/widgets/BurgerMenu';
import Logo, { LOGO } from '@/shared/ui/Logo/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Layout, useLayout } from '@/app/providers/LayoutProvider';
import { Button, ButtonSize, ButtonTheme } from '@/shared/ui/Button';

interface HeaderProps {
    className?: string;
}

const Header = (props: HeaderProps) => {
    const { layout } = useLayout();
    const hasScrolled = useHasScrolled();
    const { data: session, status } = useSession();
    const isAuthenticated = session && status === 'authenticated';

    const headerClassName = useMemo(() => {
        let className = classNames(
            'flex items-center justify-center',
            `fixed top-0 w-full z-30 transition-all`
        );

        if (layout === Layout.Default && hasScrolled) {
            className = classNames(
                className,
                'border-b border-gray-200 backdrop-blur-lg'
            );
        }

        if (layout === Layout.Default) {
            className = classNames(className, 'bg-white/50');
        }

        if (layout === Layout.Lookup) {
            className = classNames(className, 'bg-fs-primary-700');
        }

        return className;
    }, [layout, hasScrolled]);

    const headerLogo = useMemo(() => {
        if (layout === Layout.Default) {
            return LOGO.LONG_TRANSPARENT_BLACK;
        }

        return LOGO.LONG_TRANSPARENT_WHITE;
    }, [layout]);

    return (
        <header className={headerClassName}>
            <div
                className={classNames(
                    'w-full mx-5 h-16 max-w-screen-xl grid grid-cols-2 lg:grid-cols-[0.5fr_auto_0.5fr] items-center gap-x-4'
                )}
            >
                <Link
                    href={composeLink({})}
                    className="min-w-[220px] mr-auto grow basis-0 flex items-center font-display text-xl sm:text-2xl"
                >
                    <Logo type={headerLogo} link={null} />
                </Link>
                <div className="max-lg:hidden">
                    <ProductsMenu />
                </div>
                <div className="ml-auto grow basis-0 flex items-center gap-4 sm:gap-6">
                    <AppLink
                        href={composeLink({
                            path: PATHS.CONTACT_SALES
                        })}
                        size="sm"
                        className={classNames(
                            'max-md:hidden',
                            layout === Layout.Lookup ? 'text-white' : ''
                        )}
                    >
                        Contact us
                    </AppLink>
                    <Link
                        className="max-md:hidden"
                        href={composeLink({
                            subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                            path: PATHS.THREAT_LOOKUP
                        })}
                        type="button"
                    >
                        <Button
                            theme={ButtonTheme.Primary}
                            size={ButtonSize.Large}
                        >
                            <div className="flex items-center gap-2">
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    className="w-4 h-4"
                                />
                                Explore our data
                            </div>
                        </Button>
                    </Link>
                    <BurgerMenu className="lg:hidden" />
                </div>
            </div>
        </header>
    );
};

export default Header;
