import React, { useState } from 'react';
import { Button } from '@/shared/ui/Button';
import Input from '@/shared/ui/Input';
import { TestIPs } from '@/entities/Lookup/model/types/lookup';
import IocExamples from '@/entities/Lookup/ui/IocExamples/IocExamples';

interface LookupFormProps {
    ips?: TestIPs;
    examplesClassName?: string;
}

const LookupForm = (props: LookupFormProps) => {
    const { ips, examplesClassName } = props;
    const [ioc, setIoc] = useState('');
    const [loading, setLoading] = useState(false);

    const onLookup = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const encodedIoc = encodeURIComponent(ioc.trim());
        window.location.href = `/threat-lookup/${encodedIoc}`;
    };

    return (
        <section className="w-full">
            <form className="grid gap-4" onSubmit={onLookup}>
                <div className="gap-4 grid sm:grid-cols-[1fr_auto]">
                    <Input
                        id="threat-intelligence"
                        placeholder="Enter an IPv4/IPv6 address"
                        required={true}
                        value={ioc}
                        onChange={setIoc}
                    />
                    <div className="flex justify-center">
                        <Button
                            isLoading={loading}
                            className="w-full sm:w-[140px]"
                        >
                            Analyze
                        </Button>
                    </div>
                </div>
                <div className={`${examplesClassName} ml-2.5`}>
                    <IocExamples defaultExamples={ips} />
                </div>
            </form>
        </section>
    );
};

export default LookupForm;
