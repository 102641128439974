import React, { memo } from 'react';
import { Card } from '@/widgets/Card';
import { composeLink } from '@/shared/lib/links';
import { PATHS } from '@/shared/lib/constants';
import { AppLink } from '@/shared/ui/AppLink';
import Link from 'next/link';

interface OurDataEnrichmentsProps {
    showMode?: 'All' | 'API' | 'DataFeed';
}

const OurDataEnrichments = (props: OurDataEnrichmentsProps) => {
    const { showMode = 'All' } = props;

    return (
        <section>
            <div className="flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Our data enrichments
                </h2>
                <p className="max-w-5xl mx-auto text-center text-gray-500 md:text-xl">
                    Explore the output from our Data Feed and API using our{' '}
                    <AppLink
                        href={composeLink({
                            path: PATHS.THREAT_LOOKUP
                        })}
                        className="text-xl inline underline"
                    >
                        Web Lookup
                    </AppLink>
                    . Simply enter an IP address and visualize the data we
                    provide. If you prefer documentation, please refer to our{' '}
                    <AppLink
                        href="https://docs.falconsentinel.com/data-feed"
                        className="text-xl inline underline"
                    >
                        Data Feed Docs
                    </AppLink>
                    .
                </p>
                <div className="grid w-full max-w-screen-xl grid-cols-1 gap-8 md:grid-cols-3">
                    <Card
                        title="IP Threat Assessment"
                        description={
                            <>
                                <p className="mb-4">
                                    Assess whether an IP is classified as a
                                    Threat, Benign, Suspicious, or Unknown. For
                                    identified threats, provide details
                                    regarding the type of the threat.
                                </p>
                                <ul>
                                    {showMode !== 'DataFeed' && (
                                        <>
                                            <li>- Verdict</li>
                                            <li>- Threat Score</li>
                                        </>
                                    )}
                                    <li>- Threat Type</li>
                                    <li>- First/Last Seen</li>
                                </ul>
                            </>
                        }
                    />
                    <Card
                        title="IP Geolocation"
                        description={
                            <>
                                <p className="mb-4">
                                    Obtain the geolocation information for the
                                    IP address.
                                </p>
                                <ul>
                                    <li>- Country</li>
                                    <li>- Region</li>
                                    <li>- City</li>
                                </ul>
                            </>
                        }
                    />
                    {showMode !== 'DataFeed' && (
                        <Card
                            title="Proxy Detection"
                            description={
                                <>
                                    <p className="mb-4">
                                        Identify public VPNs or TOR exit nodes.
                                    </p>
                                    <ul>
                                        <li>- Tor Exit Node Flag</li>
                                        <li>- Public VPN Flag</li>
                                        <li>- Public VPN Name</li>
                                    </ul>
                                </>
                            }
                        />
                    )}
                    <Card
                        title="Reverse DNS (PTR)"
                        description={
                            <>
                                <p className="mb-4">
                                    Retrieve the PTR record and compare whether
                                    the reverse and direct resolution results
                                    match.
                                </p>
                                <ul>
                                    <li>- PTR Record</li>
                                    <li>- Records Match Flag</li>
                                </ul>
                            </>
                        }
                    />
                    {showMode !== 'DataFeed' && (
                        <Card
                            title="Benign Infrastructure Info"
                            description={
                                <>
                                    <p className="mb-4">
                                        Verify if the IP belongs to any known
                                        benign crawler, bot, or service.
                                    </p>
                                    <ul>
                                        <li>- Name</li>
                                        <li>- Description</li>
                                        <li>- Reference URLs</li>
                                    </ul>
                                </>
                            }
                        />
                    )}
                    {showMode !== 'DataFeed' && (
                        <Card
                            title="Known IPs"
                            description={
                                <>
                                    <p className="mb-4">
                                        Display information on IP addresses
                                        connected to known internet services
                                        that are not anticipated to perform
                                        legitimate infrastructure scans or
                                        crawls.
                                    </p>
                                    <ul>
                                        <li>- Name</li>
                                        <li>- Description</li>
                                        <li>- Reference URLs</li>
                                    </ul>
                                </>
                            }
                        />
                    )}
                    <Card
                        title="AS Info"
                        description={
                            <>
                                <p className="mb-4">
                                    Display autonomous system details.
                                </p>
                                <ul>
                                    <li>- ASN</li>
                                    <li>- Name</li>
                                    <li>- Domain Name</li>
                                </ul>
                            </>
                        }
                    />
                    <Card
                        title="WHOIS (IP Netblock) Info"
                        description={
                            <>
                                <p className="mb-4">
                                    Retrieve IP Netblock (WHOIS) information for
                                    the IP address, supplemented with the
                                    general threat score for this block.
                                </p>
                                <ul>
                                    <li>- Netblock Threat Score</li>
                                    <li>- Registrar Organization</li>
                                    <li>- Range Borders</li>
                                    <li>- Network Name</li>
                                    <li>- Organization Details</li>
                                    <li>- Admin/Tech/Abuse Contact Info</li>
                                    <li>- Last Modified</li>
                                </ul>
                            </>
                        }
                    />
                    {!['API', 'DataFeed'].includes(showMode) && (
                        <Card
                            title="Malicious Activity Details"
                            description={
                                <>
                                    <p className="mb-4">
                                        Provide specific details regarding the
                                        exact attack attempted from the IP
                                        address.
                                    </p>
                                    <ul>
                                        <li>- Type of Attack</li>
                                        <li>- Signature</li>
                                        <li>- Severity</li>
                                        <li>- CVE</li>
                                        <li>- Last Activity</li>
                                    </ul>
                                </>
                            }
                        />
                    )}
                </div>
            </div>
        </section>
    );
};

export default memo(OurDataEnrichments);
