import React, { memo, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { TestIPs } from '@/entities/Lookup/model/types/lookup';

interface IocExamplesProps {
    defaultExamples?: TestIPs;
    callback?: (ioc: string) => void;
    callbackResult?: any;
}

interface ExampleLinkProps {
    value: string;
    onClick?: (ioc: string, onSuccess?: () => void) => void;
    callbackResult?: any;
}

const ExampleLink = (props: ExampleLinkProps) => {
    const { value, onClick, callbackResult } = props;
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
    }, [router]);

    useEffect(() => {
        if (callbackResult) {
            setLoading(false);
        }
    }, [callbackResult]);

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) {
            setLoading(true);
            onClick(value);
            return;
        }

        setLoading(true);
    };

    return (
        <Link
            href={
                onClick
                    ? 'javascript:void(0);'
                    : `/threat-lookup/${encodeURIComponent(value)}`
            }
            className={`relative text-inherit underline ${
                loading ? 'pointer-events-none' : ''
            }`}
            onClick={handleClick}
        >
            {value}
            {loading && (
                <div className="z-10 absolute inset-0 flex items-center justify-center w-full h-full bg-white bg-opacity-90">
                    <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-gray-900"></span>
                </div>
            )}
        </Link>
    );
};

const IocExamples = (props: IocExamplesProps) => {
    const { defaultExamples, callback, callbackResult } = props;
    const [examples, setExamples] = useState<TestIPs>();
    const examplesCount = useMemo(
        () => (examples && Object.keys(examples).length) || 0,
        [examples]
    );

    useEffect(() => {
        if (defaultExamples) {
            setExamples(defaultExamples);
            return;
        }

        fetch('/api/threat-intelligence/examples')
            .then(res => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then(data => {
                setExamples(data);
            });
    }, []);

    function renderExamples() {
        if (!examples) {
            return <span>...</span>;
        }

        return Object.values(examples).map((ip, index) => (
            <>
                {renderExampleLink(ip)}
                {index < examplesCount - 1 && ', '}
            </>
        ));
    }

    function renderExampleLink(search: string) {
        return (
            <ExampleLink
                value={search}
                onClick={callback}
                callbackResult={callbackResult}
            />
        );
    }

    return <p>Examples: {renderExamples()}</p>;
};

export default memo(IocExamples);
