import React from 'react';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { Card } from '@/widgets/Card';
import { composeLink } from '@/shared/lib/links';
import { DESCRIPTION as DatabaseDescription } from '@/pages/threat-database';
import { DESCRIPTION as ApiDescription } from '@/pages/threat-api';
import { DESCRIPTION as LookupDescription } from '@/pages/threat-lookup';

interface ProductCardsProps {
    className?: string;
}

const ProductCards = (props: ProductCardsProps) => {
    return (
        <section className="w-full max-w-screen-xl">
            <div className="flex flex-col items-center gap-12 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Our products
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Our tools are designed to evaluate IP addresses, provide a
                    security assessment, and deliver comprehensive threat
                    intelligence information.
                </p>
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                    <Card
                        title="Lookup"
                        description={LookupDescription}
                        readMoreLinkText="Try now"
                        readMoreLink={composeLink({
                            subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                            path: PATHS.THREAT_LOOKUP
                        })}
                    />
                    <Card
                        title="API"
                        description={ApiDescription}
                        documentationLink="https://docs.falconsentinel.com/api"
                        readMoreLinkText="Try now"
                        readMoreLink={composeLink({
                            subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                            path: PATHS.THREAT_API
                        })}
                    />
                    <Card
                        title="Data Feed"
                        description={DatabaseDescription}
                        documentationLink="https://docs.falconsentinel.com/data-feed"
                        readMoreLinkText="Download samples"
                        readMoreLink={composeLink({
                            subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                            path: `${PATHS.THREAT_DATABASE}#download-samples`
                        })}
                    />
                </div>
            </div>
        </section>
    );
};

export default ProductCards;
