// @ts-nocheck
import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faMagnifyingGlass,
    faDatabase,
    faCogs
} from '@fortawesome/free-solid-svg-icons';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import Link from 'next/link';
import { DESCRIPTION as DatabaseDescription } from '@/pages/threat-database';
import { DESCRIPTION as ApiDescription } from '@/pages/threat-api';
import { DESCRIPTION as LookupDescription } from '@/pages/threat-lookup';

const ListItem = React.forwardRef(
    (
        { className, children, title, href = '#', logo, icon, ...props },
        forwardedRef
    ) => (
        <li>
            <NavigationMenu.Link asChild>
                <Link
                    href={href}
                    className={classNames(
                        'bg-gradient-to-br from-indigo-50 via-white to-cyan-100 block select-none rounded-lg text-sm p-3 leading-none no-underline outline-none transition-shadow hover:shadow-lg hover:text-black',
                        {
                            'pointer-events-none opacity-30': href === '#'
                        },
                        className
                    )}
                    {...props}
                    ref={forwardedRef}
                >
                    <div className="flex flex-col gap-3">
                        <div className="flex gap-4 items-center">
                            <FontAwesomeIcon
                                icon={icon}
                                className="w-6 h-6 text-gray-700"
                                aria-hidden
                            />
                            <div className="font-bold text-md">{title}</div>
                        </div>
                        {children && (
                            <>
                                <hr className="border-t border-gray-200" />
                                <p className="text-sm">{children}</p>
                            </>
                        )}
                    </div>
                </Link>
            </NavigationMenu.Link>
        </li>
    )
);

const ProductsMenu = () => {
    return (
        <NavigationMenu.Root
            className="relative z-[1] flex flex-1 justify-center"
            delayDuration={0}
        >
            <NavigationMenu.List className="center shadow-blackA7 m-0 flex list-none rounded-lg bg-white p-1 shadow-md">
                <NavigationMenu.Item>
                    <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-1.5 rounded-md px-3 py-2 text-sm font-medium leading-none outline-none hover:bg-gray-100 transition-colors">
                        Products
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className="w-[10px] h-[10px] relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                            aria-hidden
                        />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto rounded-xl">
                        <ul className="one m-0 grid list-none gap-4 p-[22px] sm:w-[600px]">
                            <ListItem
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                    path: PATHS.THREAT_DATABASE
                                })}
                                icon={faDatabase}
                                title="Threat Intelligence Data Feed"
                            >
                                {DatabaseDescription}
                            </ListItem>
                            <ListItem
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                    path: PATHS.THREAT_API
                                })}
                                icon={faCogs}
                                title="Threat Intelligence API"
                            >
                                {ApiDescription}
                            </ListItem>
                            <ListItem
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                    path: PATHS.THREAT_LOOKUP
                                })}
                                icon={faMagnifyingGlass}
                                title="Threat Intelligence Lookup"
                            >
                                {LookupDescription}
                            </ListItem>
                        </ul>
                    </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href="https://docs.falconsentinel.com"
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            Documentation
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.FAQ
                            })}
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            FAQ
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href={composeLink({
                                subdomain: SUBDOMAINS.THREAT_INTELLIGENCE,
                                path: PATHS.THREAT_STATISTICS
                            })}
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            Statistics
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href={composeLink({ path: PATHS.ABOUT_US })}
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            About Us
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
                    <div className="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-white" />
                </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <div className="perspective-[2000px] absolute top-full flex justify-center">
                <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-lg bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
            </div>
        </NavigationMenu.Root>
    );
};

export default ProductsMenu;
