import Layout from '@/widgets/Layout/ui/Layout';
import { PATHS } from '@/shared/lib/constants';
import { TrustedBy } from '@/widgets/TrustedBy';
import { AppHead } from '@/shared/ui/AppHead';
import React from 'react';
import { AppLink } from '@/shared/ui/AppLink';
import { Card } from '@/widgets/Card';
import { Pricing } from '@/widgets/PricingTIDF';
import { ContactUsSection } from '@/widgets/ContactUsSection';
import { Button, ButtonSize } from '@/shared/ui/Button';
import { TIDFMarketplaceButtons } from '@/widgets/PricingMarketplaceButtons/TIDFMarketplaceButtons';
import OurDataEnrichments from '@/entities/Blocks/OurDataEnrichments';
import ThreatStatistics from '@/entities/Blocks/ThreatStatistics';
import WeCoverThreatTypes from '@/entities/Blocks/WeCoverThreatTypes';
import UseCases from '@/entities/Blocks/UseCases';
import OurTIDataSources from '@/entities/Blocks/OurTIDataSources';
import TryItNow from '@/entities/Blocks/TryItNow';

export const DESCRIPTION =
    'Equips your security systems with a comprehensive list of IoCs for automatic threat detection and response, enhanced with reach threat intelligence. Available as daily updates or real-time streaming.';

export default function ThreatIntelligenceDataFeeds() {
    return (
        <Layout>
            <AppHead
                title="Threat Intelligence Data Feeds | FalconSentinel"
                description={DESCRIPTION}
            />
            <div className="flex flex-col items-center gap-12 sm:gap-16 px-5 xl:px-0">
                <h1 className="max-w-4xl text-gray-800 bg-clip-text text-center text-4xl font-bold tracking-[-0.02em] md:text-6xl">
                    Threat Intelligence Data Feed
                </h1>
                <p className="max-w-6xl text-center text-gray-500 md:text-xl">
                    {DESCRIPTION}
                </p>
                <div className="flex items-center gap-6 sm:gap-12">
                    <a href={'#pricing'}>
                        <Button size={ButtonSize.ExtraExtraLarge}>
                            Pricing
                        </Button>
                    </a>
                    <AppLink
                        href="https://docs.falconsentinel.com/data-feed"
                        size="2xl"
                    >
                        Documentation →
                    </AppLink>
                </div>

                <UseCases />
                <OurDataEnrichments showMode="DataFeed" />
                <WeCoverThreatTypes />

                <section className="w-full max-w-7xl">
                    <div className="w-full flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                        <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                            Coverage statistics
                        </h2>
                        <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                            Active malicious hosts identified over the past 14
                            days, by threat type.
                        </p>
                        <ThreatStatistics />
                    </div>
                </section>
                <OurTIDataSources />
                <section id="download-samples">
                    <div className="flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                        <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                            Download samples
                        </h2>
                        <div className="grid w-full max-w-screen-xl grid-cols-1 gap-8 md:grid-cols-3">
                            <Card
                                title="Core data feed"
                                description="Contains IoCs, their associated threat types and first/last seen date fields."
                                downloadLink="/samples/tidf/fs.2024-07-30.core.full.ips.jsonl.gz"
                                downloadLinkText="Download"
                                documentationLink="/threat-database/documentation#core-data-feed"
                            />
                            <Card
                                title="Professional data feed"
                                description="Contains all from Core, plus Country, Region, City, PTR Value and PTR Reverse Match fields."
                                downloadLink="/samples/tidf/fs.2024-07-30.professional.full.ips.jsonl.gz"
                                downloadLinkText="Download"
                                documentationLink="/threat-database/documentation#professional-data-feed"
                            />
                            <Card
                                title="Ultimate data feed"
                                description="Contains all from Professional, plus IP Netblock Score, WHOIS (Netblock) Info, ASN Number, ASN Name and ASN Domain."
                                downloadLink="/samples/tidf/fs.2024-07-30.ultimate.full.ips.jsonl.gz"
                                downloadLinkText="Download"
                                documentationLink="/threat-database/documentation#ultimate-data-feed"
                            />
                        </div>
                    </div>
                </section>
                <TIDFMarketplaceButtons />
                <section>
                    <div className="py-8 lg:py-16 -mb-8">
                        <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                            Stream newly discovered IoCs in real-time
                        </h2>
                        <p className="max-w-7xl mx-auto text-center text-gray-500 md:text-xl">
                            Our systems continuously discover new IoCs and
                            enrich them with intelligence information. This
                            information becomes immediately available in our API
                            and Lookup. However, it is added to Data Feeds only
                            once a day, with a daily export. To keep your threat
                            repository as up-to-date as possible, connect to the
                            Streaming Version of our Data Feeds. The average
                            delay between threat discovery and streaming is
                            under 5 minutes. Please contact us for more details.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a href={'#contact-us'}>
                                <Button
                                    className="w-full"
                                    size={ButtonSize.ExtraExtraLarge}
                                >
                                    Contact Us
                                </Button>
                            </a>
                        </div>
                    </div>
                </section>
                <TrustedBy />
                <Pricing />
                <TryItNow />
                <ContactUsSection />
            </div>
        </Layout>
    );
}
