const TrustedBy = () => {
    return (
        <section>
            <div className="py-8 lg:py-16 -mb-8">
                <h2 className="mb-8 lg:mb-16 text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    You’ll be in good company
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    Our solutions are already used by HiQ Finland, Deloitte,
                    Amesan Consulting, Swedbank, Cisco, Accenture, Nielsen
                    Suomi, CGI, Telia, Digital Ocean, University of Southern
                    California, and others.
                </p>
            </div>
        </section>
    );
};

export default TrustedBy;
