import { memo } from 'react';
import SHORT_TRANSPARENT_LOGO from '@/shared/assets/images/logo/logo-short-transparent.svg';
import SHORT_WHITE_LOGO from '@/shared/assets/images/logo/logo-short-white.svg';
import SHORT_BLACK_LOGO from '@/shared/assets/images/logo/logo-short-black.svg';
import LONG_TRANSPARENT_WHITE_LOGO from '@/shared/assets/images/logo/logo-long-transparent-white.svg';
import LONG_TRANSPARENT_BLACK_LOGO from '@/shared/assets/images/logo/logo-long-transparent-black.svg';
import Image from "next/image";
import Link from "next/link";
import {composeLink} from "@/shared/lib/links";

export enum LOGO {
    SHORT_TRANSPARENT = 'short-transparent',
    SHORT_WHITE = 'short-white',
    SHORT_BLACK = 'short-black',
    LONG_TRANSPARENT_WHITE = 'long-transparent-white',
    LONG_TRANSPARENT_BLACK = 'long-transparent-black',
}

interface LogoProps {
    type?: LOGO;
    link?: string | null;
}

const Logo = (props: LogoProps) => {
    const { type = LOGO.SHORT_TRANSPARENT, link = composeLink({}) } = props;

    const size = () => {
        switch (type) {
            case LOGO.LONG_TRANSPARENT_WHITE:
            case LOGO.LONG_TRANSPARENT_BLACK:
                return 'h-10 w-auto';
            default:
                return 'h-10 w-10';
        }
    }
    const src = () => {
        switch (type) {
            case LOGO.SHORT_TRANSPARENT:
                return SHORT_TRANSPARENT_LOGO;
            case LOGO.SHORT_WHITE:
                return SHORT_WHITE_LOGO;
            case LOGO.SHORT_BLACK:
                return SHORT_BLACK_LOGO;
            case LOGO.LONG_TRANSPARENT_WHITE:
                return LONG_TRANSPARENT_WHITE_LOGO;
            case LOGO.LONG_TRANSPARENT_BLACK:
                return LONG_TRANSPARENT_BLACK_LOGO;
            default:
                return SHORT_TRANSPARENT_LOGO;
        }
    }

    const renderImage = () => (
        <Image
            src={src()}
            alt="FalconSentinel"
            className={size()}
        />
    );

    if(link) {
        return (
            <Link href={link}>
                {renderImage()}
            </Link>
        );
    }

    return renderImage();
};

export default memo(Logo);
