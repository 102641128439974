import React, { memo } from 'react';
import OurDataSources from '@/entities/Blocks/OurDataSources';

const OurTIDataSources = () => {
    return (
        <section>
            <div className="flex flex-col items-center gap-12 sm:gap-16 py-8 lg:py-16">
                <h2 className="text-3xl font-extrabold text-center text-gray-800 md:text-4xl">
                    Our Threat Intelligence data sources
                </h2>
                <p className="max-w-4xl mx-auto text-center text-gray-500 md:text-xl">
                    By combining data from the following multiple sources, our
                    products provide you with a comprehensive and accurate view
                    of the threat landscape, enabling you to take proactive
                    steps to protect your organization from cyber threats.
                </p>
                <OurDataSources />
            </div>
        </section>
    );
};

export default memo(OurTIDataSources);
